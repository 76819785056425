import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from '../components/layout';
import { PageHeader } from '../components/page-header';
import * as css from './404.module.css';

// markup
const NotFoundPage = () => {
  return (
    <Layout className={css.wrapper}>
      <Helmet>
        <title>Not Found - Dan Dean</title>
      </Helmet>
      <PageHeader variant="project" title="Not Found" categoryLabel="404" />
    </Layout>
  );
};

export default NotFoundPage;
